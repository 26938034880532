import { Component } from '@angular/core';
import { BackendService } from '../backend.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gpt4chat',
  templateUrl: './gpt4chat.component.html',
  styleUrls: ['./gpt4chat.component.scss']
})
export class Gpt4chatComponent {

  userinput: string = '';

  systemInput: string = '';
  textBoxContent: string = '';
  response: string = '';
  conversation: any[] = [];
  disableButton: Boolean = false;

  constructor(private backendService: BackendService, private router: Router){}

  ngOnInit(){
    this.clearStorage();
    let signedIn = localStorage.getItem('signedIn');
    if(!signedIn || signedIn !== 'true'){
      this.router.navigateByUrl('');
    }
  }

  displayMessage(message: any, type: any) {
    var chatMessages = document.getElementById('chat-messages');
    if (chatMessages) {
      var messageWrapper = document.createElement('div');
      messageWrapper.classList.add('message');

      if (type === 'user') {
        messageWrapper.classList.add('user-message');
        var statusSpan = document.createElement('span');
        statusSpan.classList.add('message-status');
        statusSpan.textContent = 'Delivered';
        messageWrapper.appendChild(statusSpan);
      } else {
        messageWrapper.classList.add('bot-message');
      }

      var textSpan = document.createElement('span');
      textSpan.classList.add('message-text');
      textSpan.textContent = message;
      messageWrapper.appendChild(textSpan);

      var timeSpan = document.createElement('span');
      timeSpan.classList.add('message-time');
      var now = new Date();
      var hours = now.getHours().toString().padStart(2, '0');
      var minutes = now.getMinutes().toString().padStart(2, '0');
      timeSpan.textContent = hours + ':' + minutes;
      messageWrapper.appendChild(timeSpan);

      chatMessages.appendChild(messageWrapper);

      // Scroll to bottom of chat window
      chatMessages.scrollTop = chatMessages.scrollHeight;
    }

  }


  submitRequest() {
    if (!this.disableButton && this.textBoxContent !== '') {
      this.userinput = this.textBoxContent;
      this.displayMessage(this.userinput, 'user');
      this.textBoxContent = '';
      this.disableButton = true;

      this.backendService.getResponse(this.systemInput, this.userinput, this.conversation).subscribe((response: any) => {
        const choice = response.choices[0];
        this.response = choice.message.content;
        this.displayMessage(this.response, 'bot');
        // Append the response to the conversation
        this.conversation.push({ role: 'user', content: this.userinput });
        this.conversation.push({ role: 'assistant', content: choice.message.content });

        // Update the conversation with additional messages if any
        if (response.messages) {
          response.messages.forEach((msg: any) => {
            this.conversation.push({ role: 'assistant', content: msg.content });
          });
        }
        this.userinput = '';
        this.disableButton = false;
      });
    }

  }


  addNewSystem(event:any){
    this.conversation = []
  }

  clearStorage() {
    let hours: number = 6;
    let now: any = new Date().getTime();
    let setupTime: any = localStorage.getItem('setupTime');
    if (setupTime == null) {
      localStorage.setItem('myData', 'yourData');
      localStorage.setItem('setupTime', now)
    } else {
      if (now - setupTime > hours * 60 * 60 * 1000) {
        localStorage.clear()
        localStorage.setItem('myData', 'yourData');
        localStorage.setItem('setupTime', now);
      }
    }
  }

}


