import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  private apiUrl = 'https://ece-nonprod-openai-fra.openai.azure.com/openai/deployments/gpt-4-32k/chat/completions?api-version=2023-03-15-preview'; // Replace with your backend API URL
  private apiKey = 'eaa34884eb9a4f6ba609d24d0928cdb0';
  constructor(private http: HttpClient) {}


  getResponse(systemInput: string, userInput: string, conversation?:any): Observable<string> {

    const requestBody:any = {      
      messages:[],
      max_tokens: 800,
      temperature: 0.7,
      frequency_penalty: 0,
      presence_penalty: 0,
      top_p: 0.95,
      stop: null
    };

    if(conversation.length > 0){
      requestBody.messages.push({ role: "system", content: systemInput})
      requestBody.messages.push(...conversation)
      requestBody.messages.push({ role: "user", content: userInput })
    } else {
      requestBody.messages.push({ role: "system", content: systemInput})
      requestBody.messages.push({ role: "user", content: userInput })
    }


    const headers = new HttpHeaders().set('api-key', this.apiKey);
    headers.set('Content-Type', 'application/json');
    const options = { headers};

    return this.http.post<string>(`${this.apiUrl}`, requestBody, options);
  }
}
