<div id="wrapper-main">
  <!--<h1>User Management</h1>-->
  <div *ngIf="getCurrentRoute() !== '/gpt4'">
  <!-- <nav>
    <a routerLink="/add-user">Add User</a>
    <a routerLink="/user-list">User List</a>

  </nav> -->
  </div>
  <router-outlet></router-outlet>
</div>
