<section class="login-section" >
        <div class="login-box">
          <div class="card">
            <div class="card-body">
              <h3>Sign in</h3>
              <form (ngSubmit)="login()">
                <div class="form-item-login">
                  <label class="form-label" for="name">UserName</label>
                  <input
                    type="text"
                    id="name"
                    class="input-style"
                    name="userName"
                    [(ngModel)]="userName"
                  />
                </div>
  
                <div class="form-item-login">
                  <label class="form-label" for="password">Password</label>
                  <input
                    type="password"
                    id="password"
                    class="input-style"
                    name="password"
                    [(ngModel)]="password"
                  />
                </div>
  
                <!-- Checkbox -->
                <div class="form-item-checkbox">
                  <input type="checkbox" value="" id="form1Example3" />
                  <label class="form-check-label" for="form1Example3">
                    Remember password
                  </label>
                </div>
  
                <button class="beta full-w" type="submit">
                  Login
                </button>
  
              </form>
            </div>
          </div>
        </div>
  </section>