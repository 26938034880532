import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor(private router: Router){
  }

  ngOnInit(){
    this.clearStorage();
    let signedIn = localStorage.getItem('signedIn');
    if(signedIn && signedIn === 'true'){
      this.router.navigateByUrl('gpt4chat');
    }
  }

  public userName: any;
  public password: any;

  login() {
    if (
      this.userName.toLowerCase() == 'mercedesadmin' &&
      this.password == 'R%`*]3£;;Lg:M4~0*7Mlo7CV"t9_G_'
    ) {
      localStorage.setItem('signedIn','true')
      this.router.navigateByUrl('gpt4chat');
    } 
  }

  clearStorage() {
    let hours: number = 6;
    let now: any = new Date().getTime();
    let setupTime: any = localStorage.getItem('setupTime');
    if (setupTime == null) {
      localStorage.setItem('myData', 'yourData');
      localStorage.setItem('setupTime', now)
    } else {
      if (now - setupTime > hours * 60 * 60 * 1000) {
        localStorage.clear()
        localStorage.setItem('myData', 'yourData');
        localStorage.setItem('setupTime', now);
      }
    }
  }

}
