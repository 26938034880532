<div class="insertform">
  <h2>Add User</h2>
  <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
    <div>
      <label for="ciamid">Name</label>
      <input type="text" id="ciamid" formControlName="ciamid" required>
    </div>
    <div>
      <label for="nickname">Email</label>
      <input type="email" id="nickname" formControlName="nickname" required>
    </div>
    <div>
      <label for="name">Name</label>
      <input type="text" id="name" formControlName="name" required>
    </div>
    <div>
      <label for="email">Email</label>
      <input type="email" id="email" formControlName="email" required>
    </div>
    <div>
      <label for="name">Name</label>
      <input type="text" id="name" formControlName="name" required>
    </div>
    <div>
      <label for="email">Email</label>
      <input type="email" id="email" formControlName="email" required>
    </div>
    <button type="submit" [disabled]="userForm.invalid">Add User</button>
  </form>
</div>
