<div class="section-gpt">
  <div class="insertform usr-input-box">
    <div class="form-row">
      <div class="form-item">
        <input id="system-input" type="text" [(ngModel)]="systemInput" placeholder="Enter the AI system">
        <label for="system-input">System:</label>
      </div>
    </div>
    <div class="form-row">
      <div class="form-item">
        <input id="user-input" type="text" [(ngModel)]="userInput" placeholder="Enter your prompt">
        <label for="user-input">User:</label>
      </div>
    </div>
    <button class="submit-button" [disabled]="!systemInput || !userInput" (click)="submitRequest()">Submit</button>
  </div>
  <div class="conversation-container">
    <div *ngFor="let message of conversation" class="message" [ngClass]="{'user-message': message.role === 'user', 'assistant-message': message.role === 'assistant'}">
      {{ message.content }}
    </div>
  </div>
  <div class="response-window">
    <pre>{{ response }}</pre>
    <button class="submit-button" *ngIf="response" (click)="startReply()">Reply</button>
  </div>
</div>
<div class="loading-reg">
  <div class="loading-circle"></div>
</div>
