<div class="insertform">
  <h2>User List</h2>
  <table>
    <thead>
    <tr>
      <th>ciamid</th>
      <th>Nickname</th>
      <th>Email</th>
      <th>Sociallink</th>
      <th>Preferences</th>
      <th>Description</th>


    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of users">
      <td>{{ user.ciamid }}</td>
      <td>{{ user.nickname }}</td>
      <td>{{ user.email }}</td>
      <td>{{ user.sociallink}}</td>
      <td>{{ user.preferences}}</td>
      <td>{{ user.description}}</td>
    </tr>
    </tbody>
  </table>
</div>
