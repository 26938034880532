import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';
import { UserFormComponent } from './user-form/user-form.component';
import { UserListComponent } from './user-list/user-list.component';
import {Gpt4Component} from "./gpt4/gpt4.component";
import { Gpt4chatComponent } from './gpt4chat/gpt4chat.component';
import { LoginComponent } from './login/login.component';
import { authguardGuard } from './guards/authguard.guard';

const routes: Routes = [
  { path:'', component:LoginComponent},
  { path: 'add-user', component: UserFormComponent },
  { path: 'user-list', component: UserListComponent },
  { path: 'gpt4', component: Gpt4Component },
  { path: 'gpt4chat', component: Gpt4chatComponent },
  { path: '**', redirectTo: 'app.component', pathMatch: 'full' },
];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
