<div class="chat-body">
    <div class="chat-container">
        <div id="chat-header">
            <div>
                <input id="system-input" type="text" [(ngModel)]="systemInput" (ngModelChange)="addNewSystem($event)" placeholder="Enter the AI system, (eg. author, developer..)">
            </div>
        </div>
        <div class="chat-messages" id="chat-messages">
            
        </div>
        <div *ngIf="disableButton" class="message bot-message">
            <div class="typing">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>
        </div>
        <div id="chat-input-footer" >            
            <input (keyup.enter)="submitRequest()" id="user-input" type="text" [(ngModel)]="textBoxContent" placeholder="Type your prompt...">
            <button [disabled]="disableButton" (click)="submitRequest()">Send</button>
        </div>
    </div>
</div>