import { Component } from '@angular/core';
import { BackendService } from '../backend.service';
import { HttpHeaders } from "@angular/common/http";

@Component({
  selector: 'app-gpt4',
  templateUrl: './gpt4.component.html',
  styleUrls: ['./gpt4.component.scss']
})
export class Gpt4Component {
  systemInput: string = '';
  userInput: string = '';
  response: string = '';
  conversation: any[] = [];

  constructor(private backendService: BackendService) {}

  submitRequest() {
    this.backendService.getResponse(this.systemInput, this.userInput).subscribe((response: any) => {
      const choice = response.choices[0];
      this.response = choice.message.content;

      // Append the response to the conversation
      this.conversation.push({ role: 'user', content: this.userInput });
      this.conversation.push({ role: 'assistant', content: choice.message.content });

      // Update the conversation with additional messages if any
      if (response.messages) {
        response.messages.forEach((msg: any) => {
          this.conversation.push({ role: 'assistant', content: msg.content });
        });
      }

      // Clear user input
      this.userInput = '';
    });
  }

  startReply(): void {
    // Implement logic to start a reply
    // For example, you might clear the user input and set the system input as the last assistant message
    this.userInput = '';
    this.systemInput = this.conversation.slice().reverse().find(msg => msg.role === 'assistant')?.content || '';
  }
}
